import { render, staticRenderFns } from "./DefaultLayout.vue?vue&type=template&id=3dbc1728&scoped=true&"
import script from "./DefaultLayout.vue?vue&type=script&lang=js&"
export * from "./DefaultLayout.vue?vue&type=script&lang=js&"
import style0 from "./DefaultLayout.vue?vue&type=style&index=0&id=3dbc1728&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dbc1728",
  null
  
)

export default component.exports