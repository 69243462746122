import Vue from 'vue'
import App from './App'
import i18n from './i18n'
import { router } from './router'
import { VueAxios } from './utils/request'

import {
  Button,
  NavBar,
  Icon,
  Sidebar,
  SidebarItem,
  Popup,
  Tag,
  Swipe,
  SwipeItem,
  Toast,
  Grid,
  GridItem,
  Image,
  Lazyload,
  List,
  Cell,
  Skeleton,
  Col,
  Row,
  Divider,
  Loading,
} from 'vant'

Vue.use(Button)
Vue.use(NavBar)
Vue.use(Icon)
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(Popup)
Vue.use(Tag)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Image)
Vue.use(Lazyload)
Vue.use(List)
Vue.use(Cell)
Vue.use(Skeleton)
Vue.use(Col)
Vue.use(Row)
Vue.use(Divider)
Vue.use(Loading)

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)

Vue.prototype.$toast = Toast

new Vue({
  i18n,
  router,
  el: '#app',
  render: (h) => h(App),
})
